/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
import type { NextPage } from 'next'
import { useIsMobile } from '@/hooks/useDeviceDetect'
import { CartInfoProvider } from '@/hooks/cart'
import { getInitialProps } from '@/services/ExternalPage'
import { createPortal } from 'react-dom'
import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import type {
  ICategoryPageFields,
  IResponsiveImageWithCopyFields,
} from '@/types/Contentful'
import { CustomerProvider } from '@/hooks/customer'

const Default = dynamic(import('@/layouts/default'))
const ResponsiveImageWithCopy = dynamic(import('@/components/Content/ResponsiveImageWithCopy'))

type ExternalPageProps = {
  pageHtml?: string
  pageTitle?: string
  pageDescription?: string
  sitewide?: Record<string, any>

  pageResourceUrl?:string

  contentfulBlockBanner?: ICategoryPageFields & {
    headerStrip: IResponsiveImageWithCopyFields & { src: unknown }
  }
}
type Portal = ReturnType<typeof createPortal>
type PortalSetter = React.Dispatch<React.SetStateAction<Portal | null>>
const trySettingComponentPortal = (setter: PortalSetter) => (
  targetId: string,
) => (nodeRoRender:React.ReactNode) => {
  const targetElement = document.getElementById(targetId)
  if (targetElement) {
    setter(createPortal(nodeRoRender, targetElement))
  }
}

const ExternalPage: NextPage<ExternalPageProps> = ({
  pageHtml = '',
  pageTitle = '',
  pageDescription = '',
  sitewide = {},
  contentfulBlockBanner,
}) => {
  const [categoryBanner, setCategoryBanner] = useState<Portal | null>(null)
  const [mobileCategoryBanner, setMobileCategoryBanner] = useState<Portal | null>(null)

  const isMobile = useIsMobile()
  const marginTop = isMobile ? '56px' : '41px'

  /*
  Use effect to add Contentful's banner to CLP Shogun pages
  We have two portals in case user create different ids
  for desktop and mobile on Shogun
  */
  useEffect(() => {
    if (contentfulBlockBanner?.headerStrip && contentfulBlockBanner?.slug) {
      const headerStrip = contentfulBlockBanner?.headerStrip
      const targetNode = (
        <ResponsiveImageWithCopy
          containerLink={headerStrip?.containerLink}
          desktop={headerStrip?.desktop}
          listOfLinks={headerStrip?.listOfLinks}
          mainTitle={headerStrip?.mainTitle}
          mobile={headerStrip?.mobile}
          src={headerStrip?.src}
          titleSubtext={headerStrip?.titleSubtext}
          titleSupertext={headerStrip?.titleSubtext}
        />
      )
      const portalTargets: [PortalSetter, string][] = [
        [setCategoryBanner, `clp-${contentfulBlockBanner.slug}-multiLinkBanner`],
        [setMobileCategoryBanner, `clp-${contentfulBlockBanner.slug}-multiLinkBanner-mobile`],
      ]
      portalTargets.forEach(([setter, targetId]) => {
        trySettingComponentPortal(setter)(targetId)(targetNode)
      })
    }
  }, [contentfulBlockBanner])

  return (
    <CartInfoProvider>
      <CustomerProvider>
        <Default
          sitewide={sitewide}
          meta={{ description: pageDescription, title: pageTitle }}
        >
          <div style={{ marginTop }} dangerouslySetInnerHTML={{ __html: pageHtml }} />
          {categoryBanner || null}
          {mobileCategoryBanner || null}
        </Default>
      </CustomerProvider>
    </CartInfoProvider>
  )
}

ExternalPage.getInitialProps = getInitialProps

export default ExternalPage
