/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
import { error, log } from '@/services/Log'
import { handlePageNotFound } from '@/helpers/navigationHelpers/handlePageNotFound'
import type { NextPageContext } from 'next'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { READONLY_BC_TOKEN, SHOP_HASH, SHOP_ORIGIN } from '@/services/Configuration'
import { timePromise } from '@/helpers/logWithTiming'

type BigcommercePage = {
  url: string
  id: number
  channel_id: number
  name: string
  is_visible: boolean
  parent_id: number
  sort_order: number
  type: 'page' | 'raw' | 'contact_form' | 'feed' | 'link' | 'blog'
  is_homepage: boolean
  is_customers_only: boolean
  meta_title: string
  meta_keywords: string
  meta_description: string
  search_keywords: string
}

type BigcommercePagesList = Array<BigcommercePage>

type BigcommercePageResponse = {
  data: BigcommercePagesList
  meta: {
    pagination: {
      total: number
      count: number
      per_page: number
      current_page: number
      total_pages: number
    }
  }
}

type BigcommercePageResource = {
  body: string
  meta_title: string
  meta_description: string
}

const BC_CONTENTFUL_ID_PATTERN = /(id="clp-)(.+)(-multiLinkBanner")/

const getPageByPageNumber = async (pageNumber: number): Promise<BigcommercePageResponse> => {
  const [{ default: Axios }] = await allPromisesWithRetries(() => [import('axios')])
  return (await Axios.get<BigcommercePageResponse>(`${SHOP_ORIGIN}/stores/${SHOP_HASH}/v3/content/pages?limit=10s&page=${pageNumber}`, {
    headers: { 'X-Auth-Token': READONLY_BC_TOKEN },
  })).data
}

const findPageResourceInList = (bigcommercePagesList: BigcommercePagesList) => (
  (pageResourceUrl: string): BigcommercePage | null => (
    bigcommercePagesList.find((page) => page.url === `/${pageResourceUrl}`) || null
  )
)

const getPageXmlByWithPageId = async (
  pageId: string | number,
): Promise<BigcommercePageResource> => {
  const [{ default: Axios }] = await allPromisesWithRetries(() => [import('axios')])
  const rawXml = (await Axios.get<BigcommercePageResource>(`https://api.bigcommerce.com/stores/${SHOP_HASH}/v2/pages/${pageId}`, { headers: { 'X-Auth-Token': READONLY_BC_TOKEN } })).data
  return rawXml
}

const findHomePageResourceInListsOfPages = (bigcommercePagesList: BigcommercePagesList) => (
  bigcommercePagesList.find((page) => page.is_homepage === true)?.id || null
)

const getPageResourceIdFromListsOfPages = (
  bigcommercePagesLists: BigcommercePagesList[],
  pageResourceUrl: string,
) => (
  pageResourceUrl === ''
    ? findHomePageResourceInListsOfPages(bigcommercePagesLists.flat())
    : (findPageResourceInList(bigcommercePagesLists.flat())(pageResourceUrl)?.id || null)
)

const getPageResource = async (pageResourceUrl: string): Promise<{
  body: string,
  meta_title: string,
  meta_description: string,
}> => {
  const { data: list, meta: { pagination } } = (await getPageByPageNumber(1))
  let pageId = getPageResourceIdFromListsOfPages([list], pageResourceUrl)
  if (!pageId) {
    const extraPages = await Promise.all(
      // Create an array of page numbers, starting at 2, and ending at the total number of pages
      Array.from({ length: pagination.total_pages - 1 }, (_, index) => index + 2)
      // Map each page number to a promise that resolves to the page data
        .map((pageNumber) => getPageByPageNumber(pageNumber)),
    )
    pageId = getPageResourceIdFromListsOfPages(extraPages.map(({ data }) => data), pageResourceUrl)
  }
  if (!pageId) {
    throw new Error('Page not found in catalog')
  }
  const rawXml = await getPageXmlByWithPageId(pageId)
  return rawXml
}

const getContentfulCategoryPage = async (rawBCHtml: string, pageResourceUrl:string) => {
  if (pageResourceUrl.startsWith('clp')) {
    const contentfulSlug = rawBCHtml
      ?.match(BC_CONTENTFUL_ID_PATTERN)?.[0]
      .replace(BC_CONTENTFUL_ID_PATTERN, '$2')
    if (contentfulSlug) {
      try {
        const [{ categoryPage }] = await allPromisesWithRetries(() => [
          import('@/services/Content/categoryPage'),
        ])
        return await categoryPage({ id: contentfulSlug })
      } catch (e) {
        error(e)
      }
    }
  }
  return undefined
}

export const getInitialProps = async ({ res, asPath }: NextPageContext) => {
  log('ExternalPage: starting getInitialProps()')
  res?.setHeader?.(
    'cache-control',
    'public, s-maxage=900',
  )
  const pageResourceUrl = (
    `${asPath || ''}`
      .split('?')[0]
      ?.split('/')
      ?.reduce((acc, item, index) => (
        index > 0 ? `${acc}${index > 1 ? '/' : ''}${item}` : ''
      ), '')
  )
  try {
    /* Cache results, maybe timeout for 5 min / 15-min */
    /* API call rate limits and caps are a sticking point and risk */
    log('ExternalPage: starting getBCPage()')

    const pageResources = await timePromise({
      event: 'ExternalPage::getPageResource',
      pageResourceUrl,
    })(getPageResource(pageResourceUrl))
    log('pageResources', pageResources)

    const contentfulBlockBanner = await timePromise({
      event: 'ExternalPage::getContentfulCategoryPage',
      pageResourceUrl,
    })(getContentfulCategoryPage(
      pageResources?.body, pageResourceUrl,
    ))

    return {
      contentfulBlockBanner,
      pageHtml: pageResources.body,
      pageTitle: pageResources.meta_title,
      pageDescription: pageResources.meta_description,
    }
  } catch (e) {
    log(`No page found for ${pageResourceUrl}`)
    const path = pageResourceUrl.replace(/^.*=/, '')
    return handlePageNotFound(res, `clpslug=${path}`)
  }
}

export default getInitialProps
