/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
import { SHOP_ORIGIN } from '@/services/Configuration'
import type { NextPageContext } from 'next'

export const handlePageNotFound = (res : NextPageContext['res'], query: string | {
  [key: string]: string
}) => {
  const params = new URLSearchParams(query)
  const location = `${SHOP_ORIGIN}/PageNotFound?${params}`
  if (process.browser && typeof window !== 'undefined' && window.location.href) {
    window.location.href = location
  } else if (res && typeof res.writeHead === 'function') {
    // Tell 302 proxy not to cache response
    // https://docs.edgio.co/guides/caching#section_caching_private_responses
    res?.setHeader?.('cache-control', 'private')
    res?.writeHead?.(302, { location })
    res.end()
  }
  return { statusCode: 302 } as any
}

export default handlePageNotFound
